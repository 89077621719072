import React, { memo } from 'react';
import { graphql } from 'gatsby';
import { MainPage } from '../templates/MainPage';
import SeoText from 'components/SeoText';
import { Helmet } from "react-helmet"

const SemkaPrezentaczionnykhRolikov = () => {
  return (
    <>
      <Helmet>
        <title>Съемка презентационных роликов в Москве заказать в Elandic</title>
        <meta name="description" content="Заказать cъемка презентационных роликов о компании в Москве цена услуг от видеопродакшена Elandic. Создание презентационных роликов на заказ в студии." />
        <meta name="keywords" content="презентационный ролик, снять, о компании, заказать, создание" />
      </Helmet>
      <MainPage>
        <SeoText title='Съемка имиджевых роликов'>
          <p>Качественный имиджевый ролик является ключом к успеху любого бизнеса. Он не только укрепляет позиции бренда на рынке, но и помогает выстроить доверительные отношения с потребителями. </p>

          <p>Конкуренция на рынке Москвы достигает максимальных высот, и создание эффективного рекламного видео становится первостепенной задачей для любого бизнеса. Именно здесь на помощь приходит видеопродакшн Elandic, предлагающий профессиональные услуги по съемке имиджевых роликов в Москве.</p>

          <p>Студия Elandic сочетает в себе опыт, творческую мысль и передовые технологии производства, чтобы предложить клиентам нечто большее, чем просто рекламный ролик. Мы строим сюжет каждого видео в тесном сотрудничестве с заказчиком, чтобы точно передать его видение и ценности бренда.</p>

          <p>Наши услуги охватывают весь процесс создания имиджевого ролика — от разработки концепции и сценария до пост продакшена. Профессионализм нашей команды гарантирует, что каждый этап реализуется на высочайшем уровне, обеспечивая превосходное качество финального продукта.</p>

          <p>Elandic — ваш надежный партнер в Москве для создания имиджевых роликов, отвечающих самым высоким стандартам качества и эффективности. С нами вы можете быть уверены, что ваш бренд получит внимание, на которое заслуживает, и выделится среди конкурентов. Заказать съемку рекламного ролика в Elandic — значит вложить в свой бизнес, его развитие и рост. Не упустите возможность изменить игру в свою пользу с помощью мощного имиджевого видео!</p>

        </SeoText>
      </MainPage>
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["mainPage", "common", "layout"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default memo(SemkaPrezentaczionnykhRolikov);
